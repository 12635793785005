@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .button-primary {
    @apply py-2 px-4 backdrop-blur-md bg-white/30 font-semibold rounded-lg shadow-md hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 duration-100 ease-out;
  }

  .button-delete {
    @apply py-2 px-4 backdrop-blur-md bg-white/30 font-semibold rounded-lg shadow-md hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 duration-100 ease-out;
  }

  .button-primary-disabled {
    @apply py-2 px-4 bg-gray-400 font-semibold rounded-lg shadow-md text-gray-700 italic cursor-not-allowed;
  }

  .pop-out {
    @apply transform transition-transform duration-300 ease-out;
    will-change: transform;
    backface-visibility: hidden;
  }

  .pop-out:hover {
    @apply scale-105;
  }

  .input-field {
    @apply block min-h-[auto] w-full rounded border-0 bg-transparent py-[0.32rem] px-3 leading-[2.15] outline-none dark:text-neutral-200 dark:placeholder:text-neutral-200;
  }

  .input-field::placeholder {
    @apply opacity-0 transition-opacity duration-200;
  }

  .input-field:focus::placeholder {
    @apply opacity-100;
  }

  .input-label {
    @apply pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[2.15] text-neutral-500 transition-all duration-200 ease-out
  }

  .card {
    @apply flex flex-col md:flex-row justify-center w-1/2 lg:w-1/3 xl:w-1/4 mx-1 my-3 border-2 border-none rounded-lg shadow-md p-4 backdrop-blur-sm cursor-pointer;
  }

  .card-search-result {
    @apply flex flex-col justify-between border-2 border-none rounded-lg shadow-md p-4 backdrop-blur-sm cursor-pointer;
  }

  .card-playlists {
    @apply flex flex-col flex-none items-stretch justify-stretch w-1/2 lg:w-1/3 xl:w-1/4 mx-1 my-3 border-2 border-none rounded-lg shadow-md p-4 backdrop-blur-sm snap-center cursor-pointer;
  }

  .scrolling-wrapper {
    @apply flex flex-nowrap mx-8 p-4 overflow-x-auto snap-proximity snap-x;
  }
  
  .text-wrapper {
    @apply flex-grow overflow-auto;
  } 

  .search-bar {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
